* {
  font-family: 'Roboto Mono',
  monospace;
}

.header {
  text-align: center;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.input-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.input-group {
  display: flex;
  margin-left: 1rem;
}

label {
  margin-right: 5px;
}